// ConfirmDeleteModal.tsx
import { faCheck, faCross, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm, text }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2 className='text-custom'>Confirmation</h2>
        <p className='text-custom'>{text}</p>
        <div className='row mt-3'>
          <div className='col'>
            <button className='btn btn-md btn-custom' onClick={onConfirm}><FontAwesomeIcon icon={faCheck} className='icon-custom-white'/> Yes</button>
          </div>
          <div className='col'>
            <button className='btn btn-md btn-light' onClick={onClose}><FontAwesomeIcon icon={faXmark} /> Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
