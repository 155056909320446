import React, { useEffect, useState } from "react";
import {
  addDocument,
  updateDocument,
  deleteDocument,
  getDocumentById,
  getDocumentsPaginated,
  getDocumentByPropertyAndPropertyValue
} from "../firestoreFunctions";
import { WorkItem } from "../Interfaces/WorkItem";
import { Part } from "../Interfaces/Part";
import WorkItemComponent from "./WorkItemComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faEdit, faList, faSearch, faTrash, faWrench, faXmark } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import ConfirmationModal from "./ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

const Work = () => {
  const [collectionName] = useState("workCollection");
  const [workItems, setWorkItems] = useState<WorkItem[]>([]);
  const [lastDocument, setLastDocument] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [firstDocument, setFirstDocument] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [prevPages, setPrevPages] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 10;

  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);

  const [showWorkItem, setShowWorkItem] = useState(false);
  const [showAllWorkItems, setShowAllWorkItems] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionText, setActionText] = useState<string>("");
  const [actionName, setActionName] = useState<string>("");
  const [actionId, setActionId] = useState<string>("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActionText("");
    setActionName("");
    setActionId("");
  };

  const toggleWorkItem = () => {
    setShowWorkItem(true);
    setShowAllWorkItems(false);
  };

  const toggleAllWorkItems = () => {
    clearFormData();
    setShowWorkItem(false);
    setShowAllWorkItems(true);
  };

  const getTodaysDateTime = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate.toString();
  };

  // State to store form data
  const [formData, setFormData] = useState({
    id: "",
    startDate: getTodaysDateTime(),
    make: "",
    model: "",
    registrationNumber: "",
    customerName: "",
    customerContactNumber: "",
    workCarriedOut: "",
    WorkCarriedOutBy: "",
    partsUsed: [] as Part[],
    labour: "",
    totalCostIncludingLabour: "",
    endDate: "",
    customerPaid: "No",
    customerPaidDate: "",
    paymentType: "",
    mot:"No",
    mode: "add"
  });

  const clearFormData = () => {
  
     // Clear the form
     setFormData({
      id: "",
      startDate: getTodaysDateTime(),
      make: "",
      model: "",
      registrationNumber: "",
      customerName: "",
      customerContactNumber: "",
      workCarriedOut: "",
      WorkCarriedOutBy: "",
      partsUsed: [] as Part[],
      labour: "",
      totalCostIncludingLabour: "",
      endDate: "",
      customerPaid: "No",
      customerPaidDate: "",
      paymentType: "",
      mot:"No",
      mode: "add"
    });
  
  };

  const handleInputChange = (e: any, index?:number) => {
    const { name, value } = e.target;

    if(index != null)
    {
      const updatedPartsUsed = [...formData.partsUsed];
      updatedPartsUsed[index] = { ...updatedPartsUsed[index], [name]: value };
      setFormData({
        ...formData,
        partsUsed: updatedPartsUsed,
      });
    }
    else
    {
      setFormData({
        ...formData,
        [name]: name === 'registrationNumber' ? value.toUpperCase() : value
      });
    }
  };

  const handleAddPart = () => {
    
    const updatedParts = [...formData.partsUsed];
    const partId = uuidv4();    
    updatedParts.push({ id: partId, name: "", description: "", price: 0.00, quantity: 0, supplier: "" });
    setFormData({ ...formData, partsUsed: updatedParts });
  };

  const handleDeletePart = (partId:string) => {
    setActionText("Are you sure you want to delete this part ?");
    setActionName("deletePart");
    setActionId(partId);
    openModal();
  };

  const action = async() => { 

    if (actionName === "deletePart") {
      var id = actionId;
      const updatedParts = formData.partsUsed.filter(part => part.id !== id);
      setFormData({ ...formData, partsUsed: updatedParts });
      toast.success("Part deleted successfully");
    }
    else if (actionName === "cancelWorkItem") {
      clearFormData();
      toggleAllWorkItems();
    }
    else if (actionName === "deleteWorkItem") {
      var id = actionId;
      try {
        await deleteDocument(collectionName, id);
        const updatedDocuments = workItems.filter((doc) => doc.id !== id);
        setWorkItems(updatedDocuments);
        fetchDocuments();
        toast.success("Work item deleted successfully");
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }

    closeModal();

  };

  useEffect(() => {
    fetchDocuments(null, 'next');
  }, []);

  // Calculate total cost including labour on input change
  useEffect(() => {
    const totalPartsCost = formData.partsUsed.reduce((acc, part) => {
      const quantity = part.quantity || 0;
      const price = part.price || 0;
      return acc + quantity * price;
    }, 0);

    const labourCost = parseFloat(formData.labour) || 0;
    const totalCost = totalPartsCost + labourCost;

    setFormData(prevFormData => ({
      ...prevFormData,
      totalCostIncludingLabour: totalCost.toFixed(2).toString(),
    }));

  }, [formData.partsUsed, formData.labour]);


  const calculateTotalCost = (doc: any) => {

    const totalPartsCost = doc.partsUsed.reduce((acc: number, part: any) => {
      return acc + (parseFloat(part.quantity) * parseFloat(part.price));
    }, 0);
  
    const labourCost = parseFloat(doc.labour) || 0;
  
    const totalCost = totalPartsCost + labourCost;
  
    return totalCost.toFixed(2);
  };

  const fetchDocuments = async (startAfterDoc: QueryDocumentSnapshot<DocumentData> | null = null, direction: 'next' | 'previous' = 'next', endBeforeDoc: QueryDocumentSnapshot<DocumentData> | null = null) => {
    setLoading(true);
    try {
      
      setWorkItems([]);

      const { documents, lastDocument:newLastDocument, firstDocument:newFirstDocument } = await getDocumentsPaginated(collectionName, PAGE_SIZE, startAfterDoc || undefined, endBeforeDoc || undefined);
  
      const mappedDocuments = documents.map((doc:any) => ({
        id: doc.id,
        startDate: doc.startDate,
        make: doc.make,
        model: doc.model,
        registrationNumber: doc.registrationNumber,
        customerName: doc.customerName,
        customerContactNumber: doc.customerContactNumber,
        workCarriedOut: doc.workCarriedOut,
        WorkCarriedOutBy: doc.WorkCarriedOutBy,
        partsUsed: doc.partsUsed,
        labour: doc.labour,
        totalCostIncludingLabour: calculateTotalCost(doc),
        endDate: doc.endDate,
        customerPaid: doc.customerPaid,
        customerPaidDate: doc.customerPaidDate,
        paymentType: doc.paymentType,
      }));

      if (startAfterDoc && !endBeforeDoc) {
        setPrevPages(prev => [...prev, newFirstDocument as unknown as QueryDocumentSnapshot<DocumentData>]);
      } else if (!startAfterDoc && endBeforeDoc) {
        setPrevPages(prev => prev.slice(0, -1));
      }

      setWorkItems(mappedDocuments);
      setFirstDocument(newFirstDocument as unknown as QueryDocumentSnapshot<DocumentData>);
      setLastDocument(newLastDocument as unknown as QueryDocumentSnapshot<DocumentData>);
      setHasMore(documents.length === PAGE_SIZE);

    } catch (error) {
      console.error("Error fetching work items: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (lastDocument) {
      fetchDocuments(lastDocument, "next");
    }
  };

  const handlePreviousPage = () => {
    if (prevPages.length > 0) {
      const prevFirstDocument = prevPages[prevPages.length - 1];
      fetchDocuments(null,"previous" ,firstDocument as unknown as QueryDocumentSnapshot<DocumentData>);
      setFirstDocument(prevFirstDocument);
    }
  };

  const handleWorkItem = async (e: any) => {
    e.preventDefault();
    try {
      if (formData.mode === "edit") {
        await updateDocument(collectionName, formData.id, formData);
        toast.success("Work item updated successfully");
      } else {
        formData.id = uuidv4();
        await addDocument(collectionName, formData);
        toast.success("Work item added successfully");
      }
      // fetch documents
      fetchDocuments();
      // Clear the form
      clearFormData();
      // Toggle the form
      toggleAllWorkItems();
    } catch (error) {
      console.error("Error adding document:", error);
    }
  };

  const handleWorkItemCancel = (e:any) => {
    e.preventDefault();
    const text = formData.mode == 'edit' ? 'editing' : 'adding';

    setActionText('Are you sure you want to cancel ' + text + ' the work item?');
    setActionName("cancelWorkItem");
    setActionId("");
    openModal();
  };

  const handleGetWorkItem = async (id:string) => {

      await getDocumentById(collectionName, id).then((doc:any) => {
        setFormData({
          id: doc.id,
          startDate: doc.startDate,
          make: doc.make,
          model: doc.model,
          registrationNumber: doc.registrationNumber,
          customerName: doc.customerName,
          customerContactNumber: doc.customerContactNumber,
          workCarriedOut: doc.workCarriedOut,
          WorkCarriedOutBy: doc.WorkCarriedOutBy,
          partsUsed: doc.partsUsed,
          labour: doc.labour,
          totalCostIncludingLabour: calculateTotalCost(doc),
          endDate: doc.endDate,
          customerPaid: doc.customerPaid,
          customerPaidDate: doc.customerPaidDate,
          paymentType: doc.paymentType,
          mot: doc.mot,
          mode: "edit"
        });
      });

      toggleWorkItem();

  } 

  const handleDeleteDocument = async (docId: any) => {

    setActionText("Are you sure you want to delete this work item ?");
    setActionName("deleteWorkItem");
    setActionId(docId);
    openModal();
  };

  const fetchWorkItemByPlate = async (searchTerm: string, property:string) => {
    setLoading(true);
    try {

      setWorkItems([]);

      var documents =  await getDocumentByPropertyAndPropertyValue(collectionName, property, searchTerm);

      const mappedDocuments = documents.map((doc:any) => ({
        id: doc.id,
        startDate: doc.startDate,
        make: doc.make,
        model: doc.model,
        registrationNumber: doc.registrationNumber,
        customerName: doc.customerName,
        customerContactNumber: doc.customerContactNumber,
        workCarriedOut: doc.workCarriedOut,
        WorkCarriedOutBy: doc.WorkCarriedOutBy,
        partsUsed: doc.partsUsed,
        labour: doc.labour,
        totalCostIncludingLabour: calculateTotalCost(doc),
        endDate: doc.endDate,
        customerPaid: doc.customerPaid,
        customerPaidDate: doc.customerPaidDate,
        paymentType: doc.paymentType,
      }));

      setWorkItems(mappedDocuments);
      setHasMore(false);

    } catch (error) {
      console.error("Error fetching work item by plate: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchWorkItemByPlate(searchTerm, 'registrationNumber');
    setSearching(true);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearching(false); 
    fetchDocuments(null, 'next');
  };

  const formatDate = (dateString:string) => {
    if(dateString)
    {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
  
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
  
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
    return '';
  };


  return (
    <div className="container">
       <ToastContainer />
       <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={action}
        text={actionText}
      />
      <h1 className="mt-2">
        <FontAwesomeIcon icon={faWrench} className='icon-custom-white'/> Work
      </h1>

      <div className="d-flex mb-3 mt-5">
        <button className="btn btn-custom me-2" onClick={toggleAllWorkItems}>
          <FontAwesomeIcon icon={faList} className='icon-custom-white' /> All Work Items
        </button>
        { formData.mode === 'add' && (
           <button className="btn btn-custom" onClick={toggleWorkItem}>
           <FontAwesomeIcon icon={faWrench} className='icon-custom-white' /> Work Item
         </button>
        )
        }
       
      </div>
      {showAllWorkItems && (
        <div className="row mt-5">
          
          {loading && (
            <div className="loading-spinner"></div>
          )}

          <div className="search-container">
            <input
              className="form-control registrationPlateInput search m-2"
              type="text"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value.toUpperCase())}
              placeholder="Enter registration plate"
            />
            <button className="btn btn-custom" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} />
            </button>

            {
              searching && (
                <button className="btn btn-danger m-2" onClick={handleClearSearch}>
                  <FontAwesomeIcon icon={faXmark} /> Clear Results
                </button>
              )
            }
            

          </div>


          <div className="responsive-table-container">
            <div className="table-responsive">
          <table className="table table-striped">
            <thead className="table-custom">
              <tr>
                <th>Registration Plate</th>
                <th>Make</th>
                <th>Model</th>
                <th>Customer Name</th>
                <th>Contact Number</th>
                <th>Total Cost (inc) Labour</th>
                <th>Work Completed</th>
                <th>Carried Out By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {workItems.map(wk => (
              <tr key={wk.id}>
                <td>
                  <div className="registrationPlateTableContainer">
                    <div className="registrationPlateTableItem">{wk.registrationNumber}</div>
                  </div>
                </td>
                <td>{wk.make}</td>
                <td>{wk.model}</td>
                <td>{wk.customerName}</td>
                <td>{wk.customerContactNumber}</td>
                <td>{wk.totalCostIncludingLabour}</td>
                <td>{formatDate(wk.endDate)}</td>
                <td>{wk.WorkCarriedOutBy}</td>
                <td>
                  <button className="btn btn-custom m-2" onClick={() => handleGetWorkItem(wk.id)}><FontAwesomeIcon icon={faEdit} className='icon-custom-white'/>  Edit </button>
                  <button className="btn btn-danger m-2" onClick={() => handleDeleteDocument(wk.id)}><FontAwesomeIcon icon={faTrash} />  Delete</button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          </div>
          <div className="pagination-buttons">
              <button className="btn btn-custom" onClick={handlePreviousPage} disabled={prevPages.length === 0}>
               <FontAwesomeIcon icon={faChevronCircleLeft} className='icon-custom-white'/> Previous
              </button>
              <button className="btn btn-custom float-end" onClick={handleNextPage} disabled={!hasMore}>
                Next <FontAwesomeIcon icon={faChevronCircleRight} className='icon-custom-white'/>
              </button>
            </div>
          </div>
          </div>
      )}
      {showWorkItem && (
        <div className=" mt-5">
          <div className="card">
            <div className="card-header">
              <span>
                <FontAwesomeIcon icon={faWrench} className='icon-custom'/>
              </span>
              <span className="m-2">Work Item</span>
            </div>
            <div className="card-body"></div>
            <WorkItemComponent
              formData={formData}
              handleInputChange={handleInputChange}
              handleAddPart={handleAddPart}
              handleDeletePart={(partId: string) => () => handleDeletePart(partId)}
              handleWorkItem={handleWorkItem}
              handleWorkItemCancel={handleWorkItemCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Work;
