import React, { useEffect, useState } from "react";
import { Part } from "../Interfaces/Part";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faSave,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  getDocuments
} from "../firestoreFunctions";
import { Employee } from "../Interfaces/Employee";
import { Supplier } from "../Interfaces/Supplier";

interface AddUpdateBodyItemProps {
  formData: {
    startDate: string;
    make: string;
    model: string;
    registrationNumber: string;
    customerName: string;
    customerContactNumber: string;
    workCarriedOut: string;
    WorkCarriedOutBy: string;
    partsUsed: Part[];
    labour: string;
    totalCostIncludingLabour: string;
    endDate: string;
    customerPaid: string;
    customerPaidDate: string;
    paymentType: string;
    mode: string;
  };
  handleInputChange: (e: React.ChangeEvent<any>, index?:number) => void;
  handleAddPart: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeletePart: (
    partId: string
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleBodyItem: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleBodyItemCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BodyItemComponent: React.FC<AddUpdateBodyItemProps> = ({
  formData,
  handleInputChange,
  handleAddPart,
  handleDeletePart,
  handleBodyItem,
  handleBodyItemCancel,
}) => {

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const fetchData = async (collectionName:string, setData:any, mapFn:any) => {
    try {
      const documents = await getDocuments(collectionName);
      const dataList = documents.map(mapFn);
      setData(dataList);
    } catch (error) {
      console.error(`Error fetching data from ${collectionName}:`, error);
    }
  };

  useEffect(() => {
    fetchData('employeesCollection', setEmployees, (doc:any) => ({
      id: doc.id,
      name: doc.name,
      position: doc.position,
    }));

    fetchData('suppliersCollection', setSuppliers, (doc:any) => ({
      id: doc.id,
      name: doc.name,
    }));
  }, []);


  return (
    <div className="container">
      <form>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label className="mb-3">Registration Plate</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control registrationPlateInput"
                  type="text"
                  placeholder="Enter Registration Plate"
                  name="registrationNumber"
                  value={formData.registrationNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mb-3">Start Date</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="datetime-local"
                  placeholder="Enter Start Date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mb-3">Make</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Make"
                  name="make"
                  value={formData.make}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mb-3">Model</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Model"
                  name="model"
                  value={formData.model}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mb-3">Customer Name</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Customer Name"
                  name="customerName"
                  value={formData.customerName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="mb-3">Customer Contact Number</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Customer Contact Number"
                  name="customerContactNumber"
                  value={formData.customerContactNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Work Carried Out By</label>
              <div className="col-md-8 mb-3">
                <select
                  className="form-control"
                  name="WorkCarriedOutBy"
                  value={formData.WorkCarriedOutBy}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>Select employee</option>
                  {employees.map(employee => (
                    <option key={employee.id} value={employee.name}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Work Completed On</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="datetime-local"
                  placeholder="Enter End Date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>

          </div>
          <div className="col">
            <div className="form-group">
              <label className="mb-3">Work Carried Out</label>
              <div className="col-md-12 mb-3">
                <textarea
                  className="form-control customTextArea"
                  placeholder="Enter Work Carried Out"
                  name="workCarriedOut"
                  value={formData.workCarriedOut}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Labour</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Labour"
                  name="labour"
                  value={formData.labour}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Total Cost (inc parts and labour) </label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control totalCostInput"
                  type="text"
                  placeholder="Enter Total Cost (inc) Labour"
                  name="totalCostIncludingLabour"
                  value={formData.totalCostIncludingLabour}
                  onChange={handleInputChange}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Customer Paid</label>
              <div className="col-md-8 mb-3">
                <select
                  className="form-control"
                  name="customerPaid"
                  value={formData.customerPaid}
                  onChange={handleInputChange}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Payment Type</label>
              <div className="col-md-8 mb-3">
                <select
                  className="form-control"
                  name="paymentType"
                  value={formData.paymentType}
                  onChange={handleInputChange}
                >
                  <option value="No Payment">No Payment</option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="mb-3">Customer Paid Date</label>
              <div className="col-md-8 mb-3">
                <input
                  className="form-control"
                  type="datetime-local"
                  placeholder="Enter Customer Paid Date"
                  name="customerPaidDate"
                  value={formData.customerPaidDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>

          </div>
        </div>

        <hr />

        <div className="row mb-4">
          <div className="">
            <button
              type="button"
              className="btn btn-custom m-2 float-start"
              onClick={handleAddPart}
            >
              <FontAwesomeIcon icon={faAdd} className='icon-custom-white'/> Add Part
            </button>
          </div>
          <div>
            <label className="m-3">Parts Used</label>
            {formData.partsUsed.map((part, index) => (
                <div key={part.id} className="card mt-3">
                  <div className="card-header">
                    <span>
                      Part {index + 1} {part.name}
                    </span>
                    <button
                      type="button"
                      className="btn btn-danger float-end"
                      onClick={handleDeletePart(part.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label className="mb-3">Name</label>
                      <div className="col-md-8 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Part Name"
                          name="name"
                          value={formData.partsUsed[index].name}
                          onChange={(e) => handleInputChange(e,index)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-3">Description</label>
                      <div className="col-md-8 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Part Description"
                          name="description"
                          value={part.description}
                          onChange={(e) => handleInputChange(e,index)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-3">Price</label>
                      <div className="col-md-8 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Part Price"
                          value={part.price}
                          name="price"
                          onChange={(e) => handleInputChange(e,index)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-3">Quantity</label>
                      <div className="col-md-8 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Part Quantity"
                          value={part.quantity}
                          name="quantity"
                          onChange={(e) => handleInputChange(e,index)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-3">Supplier</label>
                      <div className="col-md-8 mb-3">
                        <select
                          className="form-control"
                          name="supplier"
                          value={part.supplier}
                          onChange={(e) => handleInputChange(e,index)}
                        >
                          <option value="" disabled>Select supplier</option>
                          {suppliers.map(supplier => (
                            <option key={supplier.id} value={supplier.name}>
                              {supplier.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>

        <hr />

        <div className="row m-4 text-center">
          <div className="col mt-5">
            <button
              type="button"
              className="btn btn-custom btnCustomSize"
              onClick={handleBodyItem}
            >
              <FontAwesomeIcon icon={faSave} className='icon-custom-white'/> {formData.mode == 'edit' ? 'Update' : 'Add'}
            </button>
          </div>
          <div className="col mt-5">
            <button
              type="button"
              className="btn btn-light btnCustomSize"
              onClick={handleBodyItemCancel}
            >
              <FontAwesomeIcon icon={faXmark} /> Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BodyItemComponent;
