import React, { useEffect, useState } from 'react';
import { Supplier } from '../Interfaces/Supplier';
import { addDocument, getDocuments, updateDocument, deleteDocument } from '../firestoreFunctions';
import { v4 as uuidv4 } from 'uuid';
import ConfirmationModal from './ConfirmationModal';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCheck, faEdit, faSave, faScrewdriverWrench, faTrash, faTruck, faXmark } from '@fortawesome/free-solid-svg-icons';


const Suppliers = () => {
  const [collectionName] = useState('suppliersCollection');
  const [suppliers, setsuppliers] = useState<Supplier[]>([]);
  const [newSupplier, setNewSupplier] = useState<Supplier>({ id: '', name: '' });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);

  const showSupplierFormToggle = () => {
    setShowAdd(!showAdd);
  };

  useEffect(() => {
    fetchsuppliers();
  }, []);

  const fetchsuppliers = async () => {
    try {
      const fetchedsuppliers: any[] = await getDocuments(collectionName);
      const mappedDocuments: Supplier[] = fetchedsuppliers.map(doc => ({
        id: doc.id,
        name: doc.name,
        position: doc.position,
      }));
      setsuppliers(mappedDocuments);
    } catch (error) {
      toast.error("Unable to get suppliers");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSupplier({ ...newSupplier, [name]: value });
  };

  const addSupplier = async () => {
    if (newSupplier.name) {
      newSupplier.id = uuidv4();
      try {
        await addDocument(collectionName, newSupplier);
        setNewSupplier({ id: '', name: '' });
        showSupplierFormToggle();
        fetchsuppliers();
        toast.success("Supplier added successfully");
      }
      catch (error) {
        toast.error("Unable to add Supplier");
      }
    }
  };

  const cancelAddUpdate = () => {
    setEditIndex(null);
    setNewSupplier({ id: '', name: '' });
    showSupplierFormToggle();
  };

  const editSupplier = (index: number) => {
    setEditIndex(index);
    setNewSupplier(suppliers[index]);
    showSupplierFormToggle();
  };

  const updateSupplier = async () => {
    if (newSupplier.name) {
      try {
        await updateDocument(collectionName, newSupplier.id, newSupplier);
        setNewSupplier({ id: '', name: '' });
        showSupplierFormToggle();
        fetchsuppliers();
        toast.success("Supplier updated successfully");
      } catch (error) {
        toast.error("Unable to update suppliers");
      }
    }
  };

  const openModal = (docId: string) => {
    setSelectedDocId(docId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocId(null);
  };

  const deleteSupplier = async () => {
    if (selectedDocId) {
      try {
        await deleteDocument(collectionName, selectedDocId);
        fetchsuppliers();
        closeModal();
        toast.success("Supplier deleted successfully");
      } catch (error) {
        toast.error("Unable to delete Supplier");
        closeModal();
      }
    }
  };

  return (
    <div className='container'>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={deleteSupplier}
        text="Are you sure you want to delete this supplier?"
      />
      <div className="card">
        <div className="card-header">
          <span><FontAwesomeIcon icon={faTruck} className='icon-custom'/></span><span className='m-2'>Suppliers</span>
          <button className="btn btn-custom float-end" onClick={showSupplierFormToggle}><FontAwesomeIcon icon={faAdd} /> Add Supplier</button>
        </div>
        <div className="card-body">
          {showAdd && (
            <form onSubmit={(e) => { e.preventDefault(); editIndex !== null ? updateSupplier() : addSupplier(); }}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" id="name" name="name" value={newSupplier.name} onChange={handleInputChange} />
            </div>
            <button type="submit" className="btn btn-custom"><FontAwesomeIcon icon={faSave} /> {editIndex !== null ? 'Update' : 'Add'}</button>
            <button className="btn btn-light m-2" onClick={cancelAddUpdate}><FontAwesomeIcon icon={faXmark} /> Cancel</button>
          </form>
          )}
          
          {!showAdd && (
            <React.Fragment>
              {suppliers.length === 0 && <p>No suppliers found</p>}
              {suppliers.length > 0 && (
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {suppliers.map((Supplier, index) => (
                      <tr key={index}>
                        <td>{Supplier.name}</td>
                        <td>
                          <button className="btn btn-sm btn-custom" onClick={() => editSupplier(index)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                          <button className="btn btn-sm btn-danger m-2" onClick={() => openModal(Supplier.id)}><FontAwesomeIcon icon={faTrash} /> Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </React.Fragment>
          )}
                 
        </div>
      </div> 
    </div>
  );
};

export default Suppliers;