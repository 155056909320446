import React, { useEffect, useState } from 'react';
import Employees from './Employees';
import Suppliers from './Suppliers';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faScrewdriverWrench, faTruck, faUsers } from '@fortawesome/free-solid-svg-icons';

const Settings = () => {

  const [showEmployees, setShowEmployees] = useState(true);
  const [showSuppliers, setShowSuppliers] = useState(false);

  const toggleEmployees = () => {
    setShowEmployees(true);
    setShowSuppliers(false);
  };

  const toggleSuppliers = () => {
    setShowEmployees(false);
    setShowSuppliers(true);
  };

  return (
    <div className='container'>
      <ToastContainer />
      <h1 className='mt-2'><FontAwesomeIcon icon={faGear} className='icon-custom-white' /> Settings</h1>
      <div className="d-flex mb-3 mt-5">
        <button
          className="btn btn-custom me-2"
          onClick={toggleEmployees}
        >
          <FontAwesomeIcon icon={faUsers} /> Employees
        </button>
        <button
          className="btn btn-custom"
          onClick={toggleSuppliers}
        >
          <FontAwesomeIcon icon={faTruck} /> Suppliers
        </button>
      </div>
      {showEmployees && 
        <div className='row mt-5'>
          <Employees />
        </div> 
      }
      {showSuppliers && 
        <div className='row mt-5'>
          <Suppliers />
        </div>
      }
    </div>
  );
};

export default Settings;
