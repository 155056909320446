import React, { useEffect, useState } from 'react';
import { getDocumentByPropertyAndPropertyValue, getDocuments } from '../firestoreFunctions';


const Dashboard = () => {

  const [month, setMonth] = useState<string>('');
  const [outstandingWorkDebt, setOutstandingWorkDebt] = useState<number>(0);
  const [outstandingBodyDebt, setOutstandingBodyDebt] = useState<number>(0);
  const [workItemsInCurrentMonth, setWorkItemsIncurrentMonth] = useState<number>(0);
  const [bodyItemsInCurrentMonth, setBodyItemsIncurrentMonth] = useState<number>(0);
  const [motsInCurrentMonth, setMotsIncurrentMonth] = useState<number>(0);

  useEffect(() => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    setMonth(month);

    getOutstandingWorkDebt();

    getOutstandingBodyDebt();

    getWorkItemsInCurrentMonth();

    getBodyItemsInCurrentMonth();

    getMotsInCurrentMonth();

  }, []);


  const getOutstandingWorkDebt = async () => { 

    var documents =  await getDocumentByPropertyAndPropertyValue("workCollection", "customerPaid", "No");

    const mappedDocuments = documents.map((doc:any) => ({
      id: doc.id,
      startDate: doc.startDate,
      make: doc.make,
      model: doc.model,
      registrationNumber: doc.registrationNumber,
      customerName: doc.customerName,
      customerContactNumber: doc.customerContactNumber,
      workCarriedOut: doc.workCarriedOut,
      WorkCarriedOutBy: doc.WorkCarriedOutBy,
      partsUsed: doc.partsUsed,
      totalCostIncludingLabour: doc.totalCostIncludingLabour,
      endDate: doc.endDate,
      customerPaid: doc.customerPaid,
      customerPaidDate: doc.customerPaidDate,
      paymentType: doc.paymentType,
    }));

    const totalOutstandingDebt = mappedDocuments.reduce((acc, doc) => {
      const cost = parseFloat(doc.totalCostIncludingLabour) || 0;
      return acc + cost;
    }, 0);

    setOutstandingWorkDebt(totalOutstandingDebt);

  };

  const getOutstandingBodyDebt = async () => { 

    var documents =  await getDocumentByPropertyAndPropertyValue("bodyCollection", "customerPaid", "No");

    const mappedDocuments = documents.map((doc:any) => ({
      id: doc.id,
      startDate: doc.startDate,
      make: doc.make,
      model: doc.model,
      registrationNumber: doc.registrationNumber,
      customerName: doc.customerName,
      customerContactNumber: doc.customerContactNumber,
      workCarriedOut: doc.workCarriedOut,
      WorkCarriedOutBy: doc.WorkCarriedOutBy,
      partsUsed: doc.partsUsed,
      totalCostIncludingLabour: doc.totalCostIncludingLabour,
      endDate: doc.endDate,
      customerPaid: doc.customerPaid,
      customerPaidDate: doc.customerPaidDate,
      paymentType: doc.paymentType,
    }));

    const totalOutstandingDebt = mappedDocuments.reduce((acc, doc) => {
      const cost = parseFloat(doc.totalCostIncludingLabour) || 0;
      return acc + cost;
    }, 0);

    setOutstandingBodyDebt(totalOutstandingDebt);

  };

  const getWorkItemsInCurrentMonth = async () => {
    // Fetch documents with customerPaid set to "No"
    var documents = await getDocuments("workCollection");
  
    // Get current year and month
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
  
    // Filter documents that have a startDate in the current month
    const filteredDocuments = documents.filter((doc:any) => {
      const startDate = new Date(doc.startDate);
      return startDate.getFullYear() === currentYear && startDate.getMonth() === currentMonth;
    });

    setWorkItemsIncurrentMonth(filteredDocuments.length);
  };

  const getBodyItemsInCurrentMonth = async () => {
    // Fetch documents with customerPaid set to "No"
    var documents = await getDocuments("bodyCollection");
  
    // Get current year and month
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
  
    // Filter documents that have a startDate in the current month
    const filteredDocuments = documents.filter((doc:any) => {
      const startDate = new Date(doc.startDate);
      return startDate.getFullYear() === currentYear && startDate.getMonth() === currentMonth;
    });

    setBodyItemsIncurrentMonth(filteredDocuments.length);
  };

  const getMotsInCurrentMonth = async () => { 

    var documents =  await getDocumentByPropertyAndPropertyValue("workCollection", "mot", "Yes");

    // Get current year and month
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
  
    // Filter documents that have a startDate in the current month
    const filteredDocuments = documents.filter((doc:any) => {
      const startDate = new Date(doc.startDate);
      return startDate.getFullYear() === currentYear && startDate.getMonth() === currentMonth;
    });

    setMotsIncurrentMonth(filteredDocuments.length);
  };

 
  return (
    <div className='container'>
      <div className='row mt-5'>
        <div className="col-md m-2">
          <div className="card dashboard-card text-center">
            <div className='card-header dashboard-card-custom'>
                Work Outstanding Debt (Today)
            </div>
            <div className="card-body card-body-custom">
              <p className="card-text dashboard-card-data-custom">£{outstandingWorkDebt.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md m-2">
          <div className="card dashboard-card text-center">
            <div className='card-header dashboard-card-custom'>
                Body Outstanding Debt (Today)
            </div>
            <div className="card-body card-body-custom">
              <p className="card-text dashboard-card-data-custom">£{outstandingBodyDebt.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className='col-md m-2'>
          <div className="card dashboard-card text-center">
            <div className='card-header dashboard-card-custom'>
                Work Vehicles ({month})
            </div>
            <div className="card-body card-body-custom">
              <p className="card-text dashboard-card-data-custom">{workItemsInCurrentMonth}</p>
            </div>
          </div>
        </div>
        <div className='col-md m-2'>
          <div className="card dashboard-card text-center">
            <div className='card-header dashboard-card-custom'>
                Body Vehicles ({month})
            </div>
            <div className="card-body card-body-custom">
              <p className="card-text dashboard-card-data-custom">{bodyItemsInCurrentMonth}</p>
            </div>
          </div>
        </div>
        <div className='col-md m-2'>
        <div className="card dashboard-card text-center">
            <div className='card-header dashboard-card-custom'>
                MOTs ({month})
            </div>
            <div className="card-body card-body-custom">
              <h5 className="card-title dashboard-card-data-custom">{motsInCurrentMonth}</h5>
            </div>
          </div>

        </div>
      </div> 
      <div className='row'>
        <div className="col">

        </div>
        <div className='col'>

        </div>
        <div className='col'>

        </div>
      </div> 
    </div>
  );
};

export default Dashboard;
