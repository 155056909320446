import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { auth } from "./firebaseSetup";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Link, BrowserRouter, Routes } from 'react-router-dom';
import Dashboard from "./Components/Dashboard";
import Settings from "./Components/Settings";
import { faDashboard, faGear, faSignOut, faWrench, faSprayCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Work from "./Components/Work";
import Body from "./Components/Body";

function App() {
  const user = useContext(AuthContext);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

 /*  const createAccount = async () => {
    try {
      await auth.createUserWithEmailAndPassword(
        emailRef.current!.value,
        passwordRef.current!.value
      );
    } catch (error) {
      console.error(error);
    }
  }; */

  const signIn = async () => {
    try {
      await auth.signInWithEmailAndPassword(
        emailRef.current!.value,
        passwordRef.current!.value
      );
    } catch (error) {
      const errorMessage = "Oops something went wrong!  Please check your email and password."
      toast.error(errorMessage);
    }
  };

  const signOut = async () => {
    await auth.signOut();
    localStorage.removeItem('userAuthenticated');
    window.location.href = '/';
  };

  return (
    <>
    {user ? (
        <nav className="navbar navbar-expand-lg navbar-dark bg-custom-navbar justify-content-between">
          <div className="container">
            <span className="navbar-brand">Handel Street Motors</span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <>
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item m-3">
                      <a className="nav-link btn btn-custom" href="/dashboard">
                        <FontAwesomeIcon icon={faDashboard} /> Dashboard
                      </a>
                    </li>
                    <li className="nav-item m-3">
                      <a className="nav-link btn btn-custom" href="/work">
                        <FontAwesomeIcon icon={faWrench} /> Work
                      </a>
                    </li>
                    <li className="nav-item m-3">
                      <a className="nav-link btn btn-custom" href="/body">
                        <FontAwesomeIcon icon={faSprayCan} /> Body
                      </a>
                    </li>
                    <li className="nav-item m-3">
                      <a className="nav-link btn btn-custom" href="/settings">
                        <FontAwesomeIcon icon={faGear} /> Settings
                      </a>
                    </li>
                  </ul>
                 {/*  <div className="navbar-text text-center">
                    <label className="mt-2 welcometext">{user.email}</label>
                  </div> */}
                  <div className="signOut">
                    <button onClick={signOut} className="btn btn-custom">
                      <FontAwesomeIcon icon={faSignOut} className="icon-custom-white" /> Sign Out
                    </button>
                  </div>
                </>
            </div>

          </div>
        </nav>
      ) 
      : null 
      }
      {!user ? (
        <>
        <ToastContainer />
        <div className="container mt-4" style={{ maxWidth: "500px", position: "fixed", top: "40%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <div className="logo-container">
            <img src="./logo.jpg" alt="logo"/>
          </div>
          <div className="card">
            <div className="card-header card-header-custom ">Login</div>
            <div className="card-body">
              <div className="mb-3">
                <label htmlFor="formEmail" className="form-label">Email</label>
                <input ref={emailRef} type="email" className="form-control" id="formEmail" placeholder="email" />
              </div>
              <div className="mb-3">
                <label htmlFor="formPassword" className="form-label">Password</label>
                <input ref={passwordRef} type="password" className="form-control" id="formPassword" placeholder="password" />
              </div>
              <button onClick={signIn} type="button" className="btn btn-custom width-100">Sign In</button>
            </div>
          </div>    
        </div>
        </>
      ) : (
        <>
         <BrowserRouter>
          <Routes>
              <Route path="/" element={<Dashboard />}/>
              <Route path="/dashboard" element={<Dashboard />}/>
              <Route path="/work" element={<Work/>}/>
              <Route path="/body" element={<Body/>}/>
              <Route path="/settings" element={<Settings />}/>
          </Routes>
        </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
