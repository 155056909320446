import React, { useEffect, useState } from 'react';
import { Employee } from '../Interfaces/Employee';
import { addDocument, getDocuments, updateDocument, deleteDocument } from '../firestoreFunctions';
import { v4 as uuidv4 } from 'uuid';
import ConfirmationModal from './ConfirmationModal';
import { toast } from 'react-toastify';
import { faAdd, faEdit, faSave, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Employees = () => {
  const [collectionName] = useState('employeesCollection');
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [newEmployee, setNewEmployee] = useState<Employee>({ id: '', name: '', position: '' });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);

  const showEmployeeFormToggle = () => {
    setShowAdd(!showAdd);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const fetchedEmployees: any[] = await getDocuments(collectionName);
      const mappedDocuments: Employee[] = fetchedEmployees.map(doc => ({
        id: doc.id,
        name: doc.name,
        position: doc.position,
      }));
      setEmployees(mappedDocuments);
    } catch (error) {
      toast.error("Unable to get employees");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewEmployee({ ...newEmployee, [name]: value });
  };

  const addEmployee = async () => {
    if (newEmployee.name && newEmployee.position) {
      newEmployee.id = uuidv4();
      try {
        await addDocument(collectionName, newEmployee);
        setNewEmployee({ id: '', name: '', position: '' });
        showEmployeeFormToggle();
        fetchEmployees();
        toast.success("Employee added successfully");
      }
      catch (error) {
        toast.error("Unable to add employee");
      }
    }
  };

  const cancelAddUpdate = () => {
    setEditIndex(null);
    setNewEmployee({ id: '', name: '', position: '' });
    showEmployeeFormToggle();
  };

  const editEmployee = (index: number) => {
    setEditIndex(index);
    setNewEmployee(employees[index]);
    showEmployeeFormToggle();
  };

  const updateEmployee = async () => {
    if (newEmployee.name && newEmployee.position) {
      try {
        await updateDocument(collectionName, newEmployee.id, newEmployee);
        setNewEmployee({ id: '', name: '', position: '' });
        showEmployeeFormToggle();
        fetchEmployees();
        toast.success("Employee updated successfully");
      } catch (error) {
        toast.error("Unable to update employees");
      }
    }
  };

  const openModal = (docId: string) => {
    setSelectedDocId(docId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocId(null);
  };

  const deleteEmployee = async () => {
    if (selectedDocId) {
      try {
        await deleteDocument(collectionName, selectedDocId);
        fetchEmployees();
        closeModal();
        toast.success("Employee deleted successfully");
      } catch (error) {
        toast.error("Unable to delete employee");
        closeModal();
      }
    }
  };

  return (
    <div className='container'>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={deleteEmployee}
        text='Are you sure you want to delete this employee?'
      />
      <div className="card">
        <div className="card-header">
          <span><FontAwesomeIcon icon={faUser} className='icon-custom'/></span><span className='m-2'>Employees</span>
          <button className="btn btn-custom float-end" onClick={showEmployeeFormToggle}><FontAwesomeIcon icon={faAdd} /> Add Employee</button>
        </div>
        <div className="card-body">
          {showAdd && (
            <form onSubmit={(e) => { e.preventDefault(); editIndex !== null ? updateEmployee() : addEmployee(); }}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" id="name" name="name" value={newEmployee.name} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="position" className="form-label">Position</label>
              <input type="text" className="form-control" id="position" name="position" value={newEmployee.position} onChange={handleInputChange} />
            </div>
            <button type="submit" className="btn btn-custom"><FontAwesomeIcon icon={faSave} /> {editIndex !== null ? 'Update' : 'Add'}</button>
            <button className="btn btn-light m-2" onClick={cancelAddUpdate}><FontAwesomeIcon icon={faXmark} /> Cancel</button>
          </form>
          )}
          
          {!showAdd && (
            <React.Fragment>
              {employees.length === 0 && <p>No employees found</p>}
              {employees.length > 0 && (
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Position</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((employee, index) => (
                      <tr key={index}>
                        <td>{employee.name}</td>
                        <td>{employee.position}</td>
                        <td>
                          <button className="btn btn-sm btn-custom" onClick={() => editEmployee(index)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                          <button className="btn btn-sm btn-danger m-2" onClick={() => openModal(employee.id)}><FontAwesomeIcon icon={faTrash} /> Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </React.Fragment>
          )}
                 
        </div>
      </div> 
    </div>
  );
};

export default Employees;