import 'firebase/firestore';
import { firestoreDatabase } from "./firebaseSetup";
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';


function cleanData(data:any) {

   // Check for undefined fields and log them
   for (const key in data) {
    if (data[key] === undefined) {
      console.error(`Field ${key} is undefined`);
    }
  }

  // Clean data to remove any undefined fields
  const cleanedData: {[key: string]: any} = {};
  for (const key in data) {
    if (data[key] !== undefined) {
      cleanedData[key] = data[key];
    }
  }

  return cleanedData;
}

// Create operation
export const addDocument = async (collectionName: string, data: any) => {
  try {
    const cleanedData = cleanData(data);
    await firestoreDatabase.collection(collectionName).doc(data.id).set(cleanedData);
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

// Read operation
export const getDocuments = async (collectionName: string) => {
  try {
    const snapshot = await firestoreDatabase.collection(collectionName).get();
    const documents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return documents;
  } catch (error) {
    console.error("Error getting documents: ", error);
    throw error;
  }
};

// Read operation
export const getDocumentByPropertyAndPropertyValue = async (collectionName: string, property:string, searchTerm:string) => {
  try {
    const snapshot = await firestoreDatabase.collection(collectionName).where(property, '==', searchTerm).get();
    const documents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return documents;
  } catch (error) {
    console.error("Error getting documents: ", error);
    throw error;
  }
};

// Read operation
export const getDocumentsPaginated = async (collectionName: string, limit: number, startAfterDoc?: QueryDocumentSnapshot<DocumentData>, endBeforeDoc?: QueryDocumentSnapshot<DocumentData>) => {
  try {
    let query = firestoreDatabase.collection(collectionName).orderBy('startDate').limit(limit);

    if (startAfterDoc) {
      query = query.startAfter(startAfterDoc);
    }

    if (endBeforeDoc) {
      query = query.endBefore(endBeforeDoc).limitToLast(limit);
    }

    const snapshot = await query.get();
    const documents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return {
      documents,
      lastDocument: snapshot.docs[snapshot.docs.length - 1] || null,
      firstDocument: snapshot.docs[0] || null
    };
  } catch (error) {
    console.error("Error getting documents: ", error);
    throw error;
  }
};

// Read operation
export const getDocumentById = async (collectionName:string, documentId:string) => {
  try {
    const documentRef = firestoreDatabase.collection(collectionName).doc(documentId);
    const documentSnapshot = await documentRef.get();

    if (documentSnapshot.exists) {
      return { id: documentSnapshot.id, ...documentSnapshot.data() };
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document: ", error);
    throw error;
  }
};


// Update operation
export const updateDocument = async (collectionName: string, docId: string, data: any) => {

  try {
    const cleanedData = cleanData(data);
    // Update the document with cleaned data
    await firestoreDatabase.collection(collectionName).doc(docId).update(cleanedData);
  } catch (error) {
    console.error("Error updating document: ", error);
    throw error;
  }
};

// Delete operation
export const deleteDocument = async (collectionName: string, docId: string) => {
  try {
    await firestoreDatabase.collection(collectionName).doc(docId).delete();
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw error;
  }
};
