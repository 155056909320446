import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDrR9n7WvcXfotDjrZGkVRiXZ2X_qKcDO0",
    authDomain: "handlestreetmotors.firebaseapp.com",
    projectId: "handlestreetmotors",
    storageBucket: "handlestreetmotors.appspot.com",
    messagingSenderId: "15026571720",
    appId: "1:15026571720:web:31e764188be1145ec998fd",
    measurementId: "G-N06NVTG2DL"
};

firebase.initializeApp(firebaseConfig);

export const firestoreDatabase = firebase.firestore();

export const auth = firebase.auth();